var moduleComponentRecentBlogsDirective = angular.module(
  "canf.componentRecentBlogsDirective",
  ["simplePagination"]
)

// componentRecentBlogs
let componentRecentBlogs = function($filter, ComponentsSrv) {
  return {
    restrict: "E",
    templateUrl: "components/recentBlogs.html",
    replace: true,
    scope: {
      componentSrc: "=",
      map: "=",
      baseUrl: "@",
      title: "@?",
      numPages: "="
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.itemPerPage = 1
      $scope.$watch("componentSrc", function() {
        $scope.component = ComponentsSrv.parseNewsfeeds(
          $scope.componentSrc,
          $scope.map,
          $scope.baseUrl
        )
        console.log("componentSrc", $scope.component)
        if ($scope.component) {
          if (typeof $attrs.order !== "undefined") {
            $scope.component.posts = $filter("orderBy")(
              $scope.component.posts,
              $attrs.order,
              true
            )
          }

          if (typeof $scope.title !== "undefined") {
            $scope.component.title = $scope.title
          }

          $scope.component.news = new Array()
          for (
            var i = 0, p = 0;
            i < $scope.numPages;
            i++, p += $scope.itemPerPage
          ) {
            $scope.component.news[i] = $scope.component.posts.slice(
              p,
              p + $scope.itemPerPage
            )
          }
          console.log("numPages", $scope.component.news)
        }
      })

      $scope.indexActive = 0
      $scope.indexHover = 0

      $scope.setIndex = function(index) {
        $scope.indexActive = index
      }

      $scope.setHover = function(key) {}
    }]
  }
}
componentRecentBlogs.$inject = ["$filter", "ComponentsSrv"]
moduleComponentRecentBlogsDirective.directive(
  "componentRecentBlogs",
  componentRecentBlogs
)
