let canfSearch = angular.module("canf.search", [])

let SearchCtrl = function($rootScope, $scope, $route, BlockSrv, block) {
  $rootScope.menuActive = block.menu
  $rootScope.metaTitle = 'Search results for "' + block.query + '"'
  $scope.searchQuery = block.query
  $scope.searched = block.query

  $scope.search = function() {
    BlockSrv.loaded = BlockSrv.search(
      { query: $scope.searchQuery, rows: 20, start: 0 },
      function(data) {
        $scope.results = data.response.docs
        $scope.searched = $scope.searchQuery
        $route.updateParams({ query: $scope.searchQuery })
      }
    )
  }
  $scope.search()
}
SearchCtrl.$inject = ["$rootScope", "$scope", "$route", "BlockSrv", "block"]
canfSearch.controller("SearchCtrl", SearchCtrl)
