let canfSocial = angular.module("canf.social", [])

// socialSharing
let socialSharing = function(shareThis, $location) {
  function _renderShareButtons() {
    window.__sharethis__.init({
      ts: 1491916530465,
      "inline-share-buttons": {
        enabled: true,
        alignment: "left",
        font_size: 11,
        has_spacing: true,
        labels: "counts",
        min_count: 0,
        networks: ["facebook", "twitter", "linkedin", "googleplus"],
        num_networks: 4,
        padding: 8,
        radius: 4,
        show_total: false,
        size: 32,
        size_label: "small",
        spacing: 8,
        ts: 1491916530461
      }
    })
  }

  return {
    restrict: "E",
    scope: {},
    template:
      '<div class="social-buttons">' +
      '<div class="sharethis-inline-share-buttons" data-url="{{ shareUrl }}"></div>' +
      "</div>",
    link: function(scope, elem, attrs) {
      scope.shareUrl = $location.absUrl()
      $.getScript(
        "//platform-api.sharethis.com/js/sharethis.js#property=58e51a811f68e90012f2ab2e&product=inline-share-buttons"
      ).done(function(script, message) {
        _renderShareButtons()
        shareThis.getButtonInfo().catch(function() {
          setTimeout(function() {
            _renderShareButtons()
          }, 500)
        })
      })
    }
  }
}
socialSharing.$inject = ["shareThis", "$location"]
canfSocial.directive("socialSharing", socialSharing)

// shareThis
let shareThis = function($http, $location, $sce) {
  var _url = "http://count-server.sharethis.com/v2.0/get_counts"
  $sce.trustAsResourceUrl(_url)

  function _getButtonInfo() {
    return $http.jsonp(_url, {
      params: {
        url: $location.absUrl(),
        cb: "window.__sharethis__.cb2",
        refDomain: "localhost"
      },
      cache: false
    })
  }

  return {
    getButtonInfo: _getButtonInfo
  }
}
shareThis.$inject = ["$http", "$location", "$sce"]
canfSocial.factory("shareThis", shareThis)
