import "./../sass/canf.scss"

import "./canf-config"
import "./canf-templates"
import "./canf-routes"
import "./canf-services"
import "./canf-filters"
import "./directives"
import "./controllers"
import "./plugins"
import WOW from "wowjs"

const browserLang = false
const defaultLang = "es"

/* App Featured Hooks */
let MainAppInjection = [
  "ngRoute",
  "ngResource",
  "ngSanitize",
  "ngCookies",
  "ngAnimate",
  "canf.config",
  "canf.routes",
  "canf.services",
  "canf.filters",
  "canf.templates",
  "canf.mainmenuResponsive",
  "canf.home",
  "canf.search",
  "canf.page",
  "canf.theArchive",
  "BBDirectives",
  "canf.socialShare",
  "canf.test",
  "canf.fragmentsDirectives",
  "canf.sticky",
  "vcRecaptcha",
  "canf.social",
  "canf.facebookComments",
  "canf.socialCounter",
  "slugifier"
]
let app = angular.module("canfApp", MainAppInjection)

window.onload = function() {
  if (window.callPhantom) window.callPhantom()
}

// Runner
let Runner = function(
  $rootScope,
  $location,
  $locale,
  $route,
  $timeout,
  $window,
  $sce,
  $cookies,
  MenuSrv,
  TranslationSrv,
  ComponentsSrv,
  Slug,
  responsiveHelper
) {
  /** Preview Cookie is destroyed **/
  $window.onbeforeunload = function() {
    $cookies.remove("preview")
  }

  /*** Translation and Mainmenu ***/
  $rootScope.loadMainmenu = function() {
    MenuSrv.init = true
    MenuSrv.loaded = MenuSrv.query(
      { id: $rootScope.lang == "en" ? 5 : 6 },
      function(response) {
        $rootScope.mainMenu = MenuSrv.items = response.result
        angular.forEach($rootScope.mainMenu, function(item, key) {
          item.url =
            typeof item.url !== "undefined" && item.url.trim() != ""
              ? item.url
              : Slug.slugify(item.title)

          if (
            typeof item.children !== "undefined" &&
            item.children.length > 0
          ) {
            item.uls = new Array()
            angular.forEach(item.children, function(child, i) {
              if (i % 2 == 0) {
                $rootScope.indexUlsRes = i / 2
                item.uls[$rootScope.indexUlsRes] = new Array()
              }

              item.uls[$rootScope.indexUlsRes].push(child)
            })
          }
        })
      }
    )
  }

  $rootScope.changeLang = function(lang) {
    console.log("Current lang: ", lang)
    $cookies.put("lang", lang), ($rootScope.lang = lang)
    $rootScope.loadMainmenu()
    TranslationSrv.get({ lang: lang, v: "1.0.68" }, function(data) {
      $rootScope.translation = data
    })
    $locale.id = lang
  }
  /*********************************************************************/

  $rootScope.$on("$routeChangeStart", function(event, next, current) {
    // console.log(event, next, current);
    // console.log('$routeChangeStart', $location.path());
    ga("set", "page", $location.path())
    ga("send", "pageview")

    var currentPath = current ? current.originalPath : "empty"
    var nextPath = next.originalPath

    console.log("Starting 1 to leave %s to go to %s", currentPath, nextPath)
  })

  $rootScope.$on("$locationChangeStart", function(event, newUrl, oldUrl) {
    // both newUrl and oldUrl are strings
    console.log("Starting 2 to leave %s to go to %s", oldUrl, newUrl)
  })

  $rootScope.$on("$routeChangeSuccess", function() {
    console.log("Success change of route")
  })

  $rootScope.$on("$routeChangeError", function(event, newUrl, oldUrl, rejection) {
    console.log("Route error: ", event, newUrl, oldUrl, rejection)
  })
  

  if (
    $location.path().indexOf("/en") >= 0 ||
    ($location.search() &&
      $location.search().lang &&
      $location.search().lang == "en")
  ) {
    $rootScope.changeLang("en")
  } else if (
    $location.path().indexOf("/es") >= 0 ||
    ($location.search() &&
      $location.search().lang &&
      $location.search().lang == "es")
  ) {
    $rootScope.changeLang("es")
  } else if (!MenuSrv.init) {
    let lang = defaultLang

    if (browserLang) {
      let languageBrowser =
        window.navigator.userLanguage || window.navigator.language
      lang = languageBrowser.toLowerCase().substring(0, 2) == "es" ? "es" : "en"
    }

    $rootScope.changeLang($cookies.get("lang") ? $cookies.get("lang") : lang)
  }

  /*** Set Default $rootScope ***/
  $rootScope.metaUrl = $location.absUrl()
  $rootScope.metaCanonical =
    $location.protocol() +
    "://" +
    $location.host() +
    ($location.port() ? ":" + $location.port() : "") +
    "/"
  $rootScope.responsiveHelper = responsiveHelper
  new WOW.WOW().init()

  $rootScope.findComponent = function(components, componentID, elementID) {
    return ComponentsSrv.find(components, componentID, elementID)
  }

  $rootScope.trustAsHtml = function(html) {
    $sce.trustAsHtml(html)
  }
  /**********************************************************************************/

  /**********************************************************/
}
Runner.$inject = [
  "$rootScope",
  "$location",
  "$locale",
  "$route",
  "$timeout",
  "$window",
  "$sce",
  "$cookies",
  "MenuSrv",
  "TranslationSrv",
  "ComponentsSrv",
  "Slug",
  "responsiveHelper"
]
app.run(Runner)

// AppCtrl
let AppCtrl = function(
  $rootScope,
  $scope,
  $route,
  $cookies,
  $location,
  TranslationSrv,
  ThirdPartySrv
) {
  /*** Set Preview cookie ***/
  if ($location.search().preview) {
    $rootScope.isPreview = true
  }

  /*** Sign Up or News ***/
  $scope.submitted = false
  $scope.sendForm = function(email, submitted) {
    ThirdPartySrv.sendNewsletter(
      { email: email },
      function(response) {
        console.log("Sended", response)
        $scope.submitted = true
      },
      function(response) {
        console.log(response)
        alert("Error: Try again.")
      }
    )
  }

  $scope.isSubmitted = function() {
    return $scope.submitted
  }

  $scope.functionSUN = {
    sendForm: $scope.sendForm,
    isSubmitted: $scope.isSubmitted
  }
  /**********************************************/
}
AppCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$route",
  "$cookies",
  "$location",
  "TranslationSrv",
  "ThirdPartySrv"
]
app.controller("AppCtrl", AppCtrl)

// ScrollOnClick
let ScrollOnClick = function($timeout) {
  return {
    restrict: "A",
    link: function(scope, $elm, attrs) {
      $timeout(function() {
        var idToScroll =
          typeof attrs.scrollTo !== "undefined" ? attrs.scrollTo : attrs.href
        var offset =
          typeof attrs.offset !== "undefined" ? parseInt(attrs.offset) : 0
        var speed = typeof attrs.speed !== "undefined" ? attrs.speed : "slow"
        $elm.on("click", function() {
          var $target
          if (idToScroll) {
            $target = $(idToScroll)
          } else {
            $target = $elm
          }

          $("html, body").animate(
            { scrollTop: $target.offset().top - offset },
            speed
          )
        })
      })
    }
  }
}
ScrollOnClick.$inject = ["$timeout"]
app.directive("scrollOnClick", ScrollOnClick)

// ScrollOnPage
let ScrollOnPage = function($timeout) {
  return {
    restrict: "A",
    scope: {
      scrollAction: "=?"
    },
    link: function($scope, $elm, attrs) {
      $scope.$watch("scrollAction", function() {
        if ($scope.scrollAction) {
          $timeout(function() {
            var idToScroll =
              typeof attrs.scrollTo !== "undefined"
                ? attrs.scrollTo
                : attrs.href
            var offset =
              typeof attrs.offset !== "undefined" ? parseInt(attrs.offset) : 0
            var speed =
              typeof attrs.speed !== "undefined" ? attrs.speed : "slow"
            var $target
            if (idToScroll) {
              $target = $(idToScroll)
            } else {
              $target = $elm
            }

            if (typeof $target.offset() !== "undefined") {
              var scrollTop = $target.offset().top
              if ($target.length)
                $("html, body").animate(
                  { scrollTop: $target.offset().top - offset },
                  speed,
                  function() {
                    /*if($target.offset().top != scrollTop){
                                  $("html, body").animate({ 'scrollTop': $target.offset().top-offset}, 0);
                              }*/
                  }
                )
            }
          }, 500)
        }
      })
    }
  }
}
ScrollOnPage.$inject = ["$timeout"]
app.directive("scrollOnPage", ScrollOnPage)

// ScrollOnTop
let ScrollOnTop = function() {
  return {
    restrict: "A",
    link: function(scope, $elm, attrs) {
      $elm.on("click", function() {
        var $target = $(document.body)
        $("html, body").animate(
          { scrollTop: 0 /*$target.offset().top*/ },
          "slow"
        )
      })
    }
  }
}
app.directive("scrollOnTop", ScrollOnTop)

// FixedWidth
let FixedWidth = function($timeout) {
  return {
    restrict: "A",
    link: function(scope, $elm, attrs) {
      $timeout(function() {
        var width = $elm.width(),
          widthParent = $elm.prev().width()
        var marginLeft = width / 2 - widthParent / 2
        console.log(width, widthParent)
        $elm.css({
          // display: "none",
          opacity: 0,
          position: "absolute",
          width: width + 1,
          "margin-left": -marginLeft
        })
      })
    }
  }
}
FixedWidth.$inject = ["$timeout"]
app.directive("fixedWidth", FixedWidth)

// ResizeToParent
let ResizeToParent = function($timeout) {
  return {
    restrict: "A",
    link: function(scope, $elm, attrs) {
      $timeout(function() {
        $elm.resizeToParent()
      })
    }
  }
}
ResizeToParent.$inject = ["$timeout"]
app.directive("resizeToParent", ResizeToParent)

// TemplateInterceptor
let TemplateInterceptor = function($q) {
  return {
    responseError: function(rejection) {
      console.log("Exc", rejection)
      var isTemplate = !!rejection.config.url.match(/^content/g)
      if (isTemplate) {
        rejection.data =
          "<div><a href=\"'" +
          rejection.config.url +
          "'\"><strong>Error from interceptor.</strong></a></div>"
        return rejection
      } else {
        return $q.reject(rejection)
      }
    }
  }
}
TemplateInterceptor.$inject = ["$q"]
app.factory("templateInterceptor", TemplateInterceptor)

// BindHtmlCompile
let BindHtmlCompile = function($compile) {
  return {
    restrict: "A",
    link: function(scope, element, attrs) {
      scope.$watch(
        function() {
          return scope.$eval(attrs.bindHtmlCompile)
        },
        function(value) {
          // Incase value is a TrustedValueHolderType, sometimes it
          // needs to be explicitly called into a string in order to
          // get the HTML string.
          element.html(value && value.toString())
          // If scope is provided use it, otherwise use parent scope
          var compileScope = scope
          if (attrs.bindHtmlScope) {
            compileScope = scope.$eval(attrs.bindHtmlScope)
          }
          $compile(element.contents())(compileScope)
        }
      )
    }
  }
}
BindHtmlCompile.$inject = ["$compile"]
app.directive("bindHtmlCompile", BindHtmlCompile)

// NgMetainfo
let NgMetainfo = function() {
  return {
    scope: {
      ngMetainfo: "="
    },
    link: function($scope, $element) {
      $scope.$watch("ngMetainfo", function() {
        "META" == $element.prop("tagName")
          ? $element.attr("content", $scope.ngMetainfo)
          : "LINK" == $element.prop("tagName")
          ? $element.attr("href", $scope.ngMetainfo)
          : $element.html($scope.ngMetainfo)
      })
    }
  }
}
app.directive("ngMetainfo", NgMetainfo)

// NumbersOnly
let NumbersOnly = function() {
  return {
    require: "ngModel",
    link: function(scope, element, attrs, modelCtrl) {
      modelCtrl.$parsers.push(function(inputValue) {
        // this next if is necessary for when using ng-required on your input.
        // In such cases, when a letter is typed first, this parser will be called
        // again, and the 2nd time, the value will be undefined
        if (inputValue == undefined) return ""
        var transformedInput = inputValue.replace(/[^0-9]/g, "")
        if (transformedInput != inputValue) {
          modelCtrl.$setViewValue(transformedInput)
          modelCtrl.$render()
        }

        return transformedInput
      })
    }
  }
}
app.directive("numbersOnly", NumbersOnly)

// CfFocus
let CfFocus = function($timeout) {
  return {
    link: function(scope, element, attrs) {
      scope.$watch(attrs.cfFocus, function(value) {
        // console.log('value=',value);
        if (value === true) {
          $timeout(function() {
            element.focus()
            // scope[attrs.smFocus] = false;
          })
        }
      })
    }
  }
}
CfFocus.$inject = ["$timeout"]
app.directive("cfFocus", CfFocus)

// BbClickToBack
let BbClickToBack = function($window) {
  return function(scope, element, attrs) {
    element.on("click", function() {
      scope.$apply(function() {
        $window.history.go(-1)
      })
    })
  }
}
BbClickToBack.$inject = ["$window"]
app.directive("bbClickToBack", BbClickToBack)

// NgEnter
let NgEnter = function() {
  return function(scope, element, attrs) {
    element.bind("keydown keypress", function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          scope.$eval(attrs.ngEnter)
        })

        event.preventDefault()
      }
    })
  }
}
app.directive("ngEnter", NgEnter)

// ClickToLocation
let ClickToLocation = function($location) {
  return {
    restrict: "A",
    link: function(scope, $elm, attrs) {
      $elm.on("click", function() {
        scope.$apply(function() {
          $location.path(attrs.clickToLocation)
        })
      })
    }
  }
}
ClickToLocation.$inject = ["$location"]
app.directive("clickToLocation", ClickToLocation)

// NgHref
let NgHref = function($rootScope, $timeout) {
  return {
    restrict: "A",
    priority: 100, // give it higher priority than built-in ng-click
    link: function(scope, element, attr) {
      $timeout(function() {
        if (
          attr.ngHref &&
          attr.ngHref.indexOf("http://") < 0 &&
          attr.ngHref.indexOf("https://") < 0
        ) {
          attr.ngHref =
            attr.ngHref.charAt(0) == "."
              ? attr.ngHref.substring(1)
              : attr.ngHref
          attr.ngHref =
            attr.ngHref.charAt(0) == "/" ? attr.ngHref : "/" + attr.ngHref
          var lang = $rootScope.lang == "es" ? "/es" : ""

          /*if(attr.ngHref.indexOf('?') == -1){
                      var qlang = "?lang=" + $rootScope.lang;
                  }else{
                      var qlang = "&lang=" + $rootScope.lang;
                  }*/

          element.attr("href", lang + attr.ngHref) // + qlang
          // console.log('attr.ngHref', attr.ngHref + qlang)
        }
      })
    }
  }
}
NgHref.$inject = ["$rootScope", "$timeout"]
app.directive("ngHref", NgHref)

// CfMetaDescription
let CfMetaDescription = function(
  $rootScope,
  $timeout,
  $filter,
  $sanitize,
  BlockSrv
) {
  return {
    restrict: "A",
    link: function(scope, element, attr) {
      $rootScope.$on("pageLoaded", function() {
        $timeout(function() {
          var description = $("body #cf-container article:first").text()
          description =
            description.trim() == ""
              ? $("body #cf-container").text()
              : description
          description = description
            .trim()
            .replace(/\r?\n|\r|\t/g, "")
            .replace(/\u00a0/g, "")
          // console.log('description', description);
          element.attr("content", $filter("limitTo")(description, 500) + "...")
        })
      })
    }
  }
}
CfMetaDescription.$inject = [
  "$rootScope",
  "$timeout",
  "$filter",
  "$sanitize",
  "BlockSrv"
]
app.directive("cfMetaDescription", CfMetaDescription)

// CfMetaImage
let CfMetaImage = function($rootScope, $timeout) {
  return {
    restrict: "A",
    link: function(scope, element, attr) {
      $rootScope.$on("pageLoaded", function() {
        $timeout(function() {
          var img = $("body #cf-container article:first img:first").attr("src")
          if (img) {
            img = img.trim() != "" ? img : ""
            element.attr("content", img)
          }
        })
      })
    }
  }
}
CfMetaImage.$inject = ["$rootScope", "$timeout"]
app.directive("cfMetaImage", CfMetaImage)
