angular.module("canf.facebookComments", []).directive("fbComments", function() {
  function createHTML(href, numposts, width) {
    return (
      '<div class="fb-comments" ' +
      'data-href="' +
      href +
      '" ' +
      'data-numposts="' +
      numposts +
      '" ' +
      'data-width="' +
      width +
      '">' +
      "</div>"
    )
  }

  return {
    restrict: "A",
    scope: {},
    link: function postLink(scope, elem, attrs) {
      attrs.$observe("pageHref", function(newValue) {
        var href = newValue
        var numposts = attrs.numposts || 5
        var width = attrs.width

        elem.html(createHTML(href, numposts, width))
        FB.XFBML.parse(elem[0])
      })
    }
  }
})
