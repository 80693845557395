import loaderWhite from "Img/loader-white.gif"
let canfTheArchive = angular.module("canf.theArchive", [
  "canf.canfTimelineDirective",
  "canf.theArchiveDrtv",
  "simplePagination",
  "ui.openseadragon"
])



// Checks a string to see if it in a valid date format
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];


  function isValidDate(s) {
    // format D(D)/M(M)/(YY)YY
    var ret = "",i
  
    var parse = s.split(" ")

    if (parse.length == 1) {
      i = parseInt(parse[0])
      if (i >= 1000 && i <= 3000) {
        ret += "January 1 " + parse[0]
      } else {
        ret = false
      }
    } else if (parse.length == 2) {
      i = parseInt(parse[1])
      if (i >= 1000 && i <= 3000) {
        if (inArray(parse[0], months)) {
          ret += parse[0]
        } else {
          ret += "January"
        }

        ret += " 1 " + parse[1]
      } else {
        ret = false
      }
    }
    return ret == "" ? s : ret
  }

  function inArray(needle, haystack) {
    var length = haystack.length
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true
    }
    return false
  }




// TheArchiveCtrl
let TheArchiveCtrl = function(
  $rootScope,
  $scope,
  $timeout,
  $location,
  $sce,
  $filter,
  block,
  TheArchiveSrv,
  BlockSrv,
  ArchiveUtilSrv,
  PagesLoadedSrv,
  PagesSrv,
  Pagination
) {
  $scope.loaderWhite = loaderWhite
  PagesSrv.checkTemplateExists(block)
  $scope.pageTpl = PagesSrv.pageTpl

  $rootScope.menuActive = block.menu
  $rootScope.metaTitle =
    typeof block.menu.childrenActive !== "undefined"
      ? $rootScope.menuActive.childrenActive.title +
        " | " +
        $rootScope.menuActive.title
      : $rootScope.menuActive.title

  $scope.blockId = block.menu.block_id
  if (typeof PagesLoadedSrv[$scope.blockId] === "undefined") {
    $rootScope.hideLoader = false
  }

  BlockSrv.loaded = BlockSrv.query(
    { id: PagesSrv.blockId, lang: $rootScope.lang },
    function(response) {
      $scope.components = response.result

      if (typeof PagesLoadedSrv[PagesSrv.blockId] === "undefined") {
        /*$timeout(function() {
                $rootScope.hideLoader = true;
            }, 500);*/
        $scope.$emit("pageLoaded")
        PagesLoadedSrv[PagesSrv.blockId] = true
      }
    }
  )

  $scope.find = function() {
    if (
      typeof $scope.query === "undefined" ||
      (typeof $scope.query !== "undefined" && $scope.query.trim() == "")
    ) {
      return false
    }

    $scope.loader = true

    TheArchiveSrv.loaded = TheArchiveSrv.find({ q: $scope.query }, function(
      response
    ) {
      // console.log("CollectiveAccess", response);
      ;($scope.displaySearchResult = true),
        ($scope.loader = false),
        ($scope.displayMsgNotFound = false)
      $scope.resultsFor = $scope.query
      if (response.ok && typeof response.results !== "undefined") {
        $scope.items = response.results
        $scope.perPage = 12
        $scope.pagination = Pagination.getNew($scope.perPage)
        $scope.pagination.numPages = Math.ceil(
          $scope.items.length / $scope.pagination.perPage
        )

        $scope.currentPage = 0;
        $scope.pageSize = 20;
        $scope.q = '';


        $scope.getData = function () {
          return $filter('filter')($scope.items, $scope.q)
        }
        
        $scope.numberOfPages=function(){
            return Math.ceil(response.results.length/$scope.pageSize);
        }

        $scope.$watch('q', function(newValue,oldValue){                       
            if(oldValue!=newValue){
            $scope.currentPage = 0;
          }
        },true);

        angular.forEach($scope.items, function(item, i) {
          if (!item['ca_objects.hierarchy.date'][0].dates_value){
            item.date = "January 1 1900";
          }else{
            item.date = item['ca_objects.hierarchy.date'][0].dates_value;
          }

          var date = isValidDate(item.date)
          if (date) {
            item.date = Date.parse(date)
            if (isNaN(item.date) == false) {
              item.date = new Date(item.date)
            } else {
              item.date = false
            }
          }


        })
        var dataSorted = $filter("orderBy")($scope.items, "date")

        $scope.items = dataSorted



        if (typeof PagesLoadedSrv[$scope.blockId] === "undefined") {
          $timeout(function() {
            $rootScope.hideLoader = true
          }, 500)
          PagesLoadedSrv[$scope.blockId] = true
        }
      } else {
        $scope.items = new Array()
        $scope.displayMsgNotFound = true
      }
    })
  }

  $scope.findAction = function() {
    /*if (!$scope.displaySearchResult) {
        	$scope.find();
        	$scope.displaySearchResult = true;
        	$scope.itemID = false;
        }*/
    $location.path("/the-archive/archive-search/" + $scope.query)
  }

  

  $scope.sceIframeUrl = function(obj) {
    var url = $filter("findIndexFromObject")(obj, 0).urls.original
    $timeout(function() {
      $scope.iframeUrl = $sce.trustAsResourceUrl(url)
    })
  }

  if (typeof block.itemID !== "undefined") {
    $scope.query = block.query
    $scope.itemID = parseInt(block.itemID.split("-")[0])
    TheArchiveSrv.loaded = TheArchiveSrv.detail({ id: $scope.itemID }, function(
      response
    ) {
      $rootScope.hideLoader = true

      ArchiveUtilSrv.parseObjectDetailSrv($scope, response)
    })
  } else if (typeof block.query !== "undefined") {
    $scope.query = block.query
    $scope.find()
    $scope.displaySearchResult = true
  }

  $scope.langMap = $rootScope.lang == "es" ? "es_ES" : "en_US"
}
TheArchiveCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$timeout",
  "$location",
  "$sce",
  "$filter",
  "block",
  "TheArchiveSrv",
  "BlockSrv",
  "ArchiveUtilSrv",
  "PagesLoadedSrv",
  "PagesSrv",
  "Pagination"
]
canfTheArchive.controller("TheArchiveCtrl", TheArchiveCtrl)

// TheArchiveSpecialCollsCtrl
let TheArchiveSpecialCollsCtrl = function(
  $rootScope,
  $scope,
  $filter,
  block,
  TheArchiveSrv,
  BlockSrv,
  ArchiveUtilSrv,
  PagesLoadedSrv,
  PagesSrv
) {
  PagesSrv.checkTemplateExists(block)

  $rootScope.menuActive = block.menu
  $rootScope.metaTitle =
    typeof block.menu.childrenActive !== "undefined"
      ? $rootScope.menuActive.childrenActive.title +
        " | " +
        $rootScope.menuActive.title
      : $rootScope.menuActive.title

  $scope.blockId = block.menu.block_id

  BlockSrv.loaded = BlockSrv.query(
    { id: PagesSrv.blockId, lang: $rootScope.lang },
    function(response) {
      $scope.components = response.result

      if (typeof PagesLoadedSrv[PagesSrv.blockId] === "undefined") {
        /*$timeout(function() {
                $rootScope.hideLoader = true;
            }, 500);*/
        $scope.$emit("pageLoaded")
        PagesLoadedSrv[PagesSrv.blockId] = true
      }
    }
  )

  if (typeof block.collID !== "undefined") {
    var slug = block.collID.split("-")
    ;($scope.collID = parseInt(slug[0])), ($scope.elID = parseInt(slug[1]))

    if (typeof block.itemID !== "undefined") {
      $scope.itemID = parseInt(block.itemID.split("-")[0])
      TheArchiveSrv.loaded = TheArchiveSrv.detail(
        { id: $scope.itemID },
        function(response) {
          ArchiveUtilSrv.parseObjectDetailSrv($scope, response)
        }
      )
    } else {
      TheArchiveSrv.collection({ id: $scope.collID }, function(response) {
        $scope.data = response
        $scope.currentPage = 0;
        $scope.pageSize = 20;
        $scope.q = '';


        $scope.getData = function () {
          return $filter('filter')($scope.data.related_objects, $scope.q)
        }
        
        $scope.numberOfPages=function(){
            return Math.ceil(response.related_objects.length/$scope.pageSize);
        }

        $scope.$watch('q', function(newValue,oldValue){                       
            if(oldValue!=newValue){
            $scope.currentPage = 0;
          }
        },true);


        angular.forEach($scope.data.related_objects, function(item, i) {
          if (!item.date) item.date = "January 1 1900";

          var date = isValidDate(item.date)
          if (date) {
            item.date = Date.parse(date)
            if (isNaN(item.date) == false) {
              item.date = new Date(item.date)
            } else {
              item.date = false
            }
          }


        })
        var dataSorted = $filter("orderBy")($scope.data.related_objects, "date")

        $scope.data.related_objects = dataSorted
      })
    }

    $scope.langMap = $rootScope.lang == "es" ? "es_ES" : "en_US"
  }
}
TheArchiveSpecialCollsCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$filter",
  "block",
  "TheArchiveSrv",
  "BlockSrv",
  "ArchiveUtilSrv",
  "PagesLoadedSrv",
  "PagesSrv"
]
canfTheArchive.controller(
  "TheArchiveSpecialCollsCtrl",
  TheArchiveSpecialCollsCtrl
)

// ArchiveUtilSrv
let ArchiveUtilSrv = function($sce, $filter, RESOURCE_IIPSRV) {
  var ArchiveSrv = new Object()

  ArchiveSrv.parseObjectDetailSrv = function(scope, response) {
    scope.itemDetail = response

    scope.file = $filter("srcLocalToRemote")(
      scope.itemDetail.representations.original.url
    )
    var mimeType =
      typeof scope.itemDetail.representations.original.mimetype !== "undefined"
        ? scope.itemDetail.representations.original.mimetype
        : ""
    if (mimeType && mimeType.indexOf("image/") > -1) {
      scope.imgUrl = scope.file
      var idPre = scope.itemDetail.representations.original.representation_id //"191";
      var path = RESOURCE_IIPSRV.path
      var server = RESOURCE_IIPSRV.url
      scope.options = {
        prefixUrl: "//openseadragon.github.io/openseadragon/images/",
        tileSources:
          server + "?DeepZoom=" + path + "image_pyramid_" + idPre + ".tiff.dzi",
        minZoomImageRatio: 1
      }
    } else if (mimeType.indexOf("application/pdf") > -1) {
      scope.pdfUrl = $sce.trustAsResourceUrl(
        "./vendors/pdf.js/web/viewer.html?file=" +
          scope.file +
          "#search=" +
          scope.query
      ) 
    } else if (mimeType.indexOf("video/") > -1) {
      scope.videoUrlYoutube = $sce.trustAsResourceUrl(
        "https://www.youtube.com/embed/" +
          scope.itemDetail.representations.original.youtube_id
      )
    } else if (mimeType.indexOf("audio/") > -1) {
      scope.audioUrl = $sce.trustAsResourceUrl(
        $filter("srcLocalToRemote")(
          scope.itemDetail.representations.original.url
        )
      )
    }
  }

  return ArchiveSrv
}
ArchiveUtilSrv.$inject = ["$sce", "$filter", "RESOURCE_IIPSRV"]
canfTheArchive.factory("ArchiveUtilSrv", ArchiveUtilSrv)
