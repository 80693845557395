let canfSocialCounter = angular.module("canf.socialCounter", [])
// socialCounterDirective
let socialCounterDirective = function(socialCounter) {
  return {
    restrict: "E",
    replace: true,
    scope: {
      url: "@?"
    },
    template:
      '<div class="social-counter">' +
      '<span class="social-shared">SHARED: {{shared}}</span>' +
      '<span class="social-comments">COMMENTS: {{comments}}</span>' +
      "</div>",
    controller: ["$scope", 
    function($scope) {
      function _getFacebookCount() {
        socialCounter.getFacebookCount($scope.url).then(success)
        function success(response) {
          $scope.comments =
            typeof response.data.share === "undefined"
              ? 0
              : response.data.share.comment_count
        }
      }

      function _getSharethisCount() {
        socialCounter.getSharethisCount($scope.url).then(success)

        function success(response) {
          $scope.shared =
            typeof response.data.total === "undefined"
              ? 0
              : response.data.total.outbound
        }
      }

      _getFacebookCount()
      _getSharethisCount()
    }]
  }
}
socialCounterDirective.$inject = ["socialCounter"]
canfSocialCounter.directive("socialCounter", socialCounterDirective)

// socialCounterFactory
let socialCounterFactory = function($http, SOCIAL_COUNTER) {
  function _getFacebookCount(href) {
    return $http
      .get(SOCIAL_COUNTER.cache_layer, {
        params: {
          url:
            SOCIAL_COUNTER.facebook + SOCIAL_COUNTER.domain + href.substring(1)
        }
      })
      .then(function(success) {
        return success
      })
      .catch(function(error) {
        return error
      })
  }

  function _getSharethisCount(href) {
    return $http
      .get(SOCIAL_COUNTER.curl_layer, {
        params: {
          url: SOCIAL_COUNTER.domain + href.substring(1)
        }
      })
      .then(function(success) {
        return success
      })
      .catch(function(error) {
        return error
      })
  }

  return {
    getFacebookCount: _getFacebookCount,
    getSharethisCount: _getSharethisCount
  }
}
socialCounterFactory.$inject = ["$http", "SOCIAL_COUNTER"]
canfSocialCounter.factory("socialCounter", socialCounterFactory)
