let canfMainmenuResponsive = angular.module("canf.mainmenuResponsive", [])

// mainmenuResponsive
let mainmenuResponsive = function($document, $timeout) {
  return {
    restrict: "E",
    templateUrl: "components/mainmenu-responsive.html",
    replace: true,
    scope: {
      items: "="
    },
    link: function($scope, $element, $attrs) {
      $timeout(function() {
        $document.find("body").bind("mouseup", function(e) {
          var container = $($element.find(".mainmenu-responsive, .cf-brand"))

          if (
            !container.is(e.target) && // if the target of the click isn't the container...
            container.has(e.target).length === 0
          ) {
            // ... nor a descendant of the container
            // $('.filter-options').slideUp('fast');
            $scope.$apply(function() {
              $scope.show = false
            })
          }
        })
      })
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.toggle = function() {
        $scope.show = !$scope.show
      }
    }]
  }
}
mainmenuResponsive.$inject = ["$document", "$timeout"]
canfMainmenuResponsive.directive("mainmenuResponsive", mainmenuResponsive)
