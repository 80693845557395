import "../../sass/canf-timeline.scss"
let canfTimelineDirective = angular.module("canf.canfTimelineDirective", [])

// canfTimeline
let canfTimeline = function(
  $window,
  $rootScope,
  $timeout,
  $filter,
  TheArchiveSrv
) {
  return {
    restrict: "E",
    templateUrl: "components/canf-timeline.html",
    replace: true,
    scope: {
      collectionId: "=",
      rangeStart: "=",
      rangeEnd: "="
    },
    link: function($scope, $element, $attrs) {
      // $timeout(function(){
      // $scope.$watch('collectionId', function(newValue){
      // if ($scope.collectionId) {
      // }
      // });
      // });
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.langMap = $rootScope.lang == "es" ? "es_ES" : "en_US"

      /*var startDate = new Date(1930, 0, 1);
            var date = new Date(startDate);
            for (var i = 0; i < 15; i++) {
                $scope.data.addRow([new Date(date), null, "Archive " + i, i+""]);
                date.setDate(date.getFullYear() + 1);
                $scope.lengthRows++;
            }
            var endDate = new Date(date);*/

      TheArchiveSrv.collection({ id: $scope.collectionId }, function(response) {
        $scope.dataObjects = response.related_objects
        // var invalids = [];
        angular.forEach($scope.dataObjects, function(item, i) {
          // var parse = item.date.split(' ');
          // if ( parse.length != 3 ) {
          // invalids.push(i);
          // }else{
          var date = isValidDate(item.date)
          if (date) {
            item.date = Date.parse(date)
            if (isNaN(item.date) == false) {
              item.date = new Date(item.date)
            } else {
              item.date = false
            }
          }
          // }
        })

        /*angular.forEach(invalids, function(index){
                    $scope.dataObjects.splice(index, 1);
                });*/

        var dataSorted = $filter("orderBy")($scope.dataObjects, "date")

        var indexLast = dataSorted.length - 1
        var startDate =
          typeof $scope.rangeStart !== "undefined" && $scope.rangeStart
            ? new Date($scope.rangeStart)
            : dataSorted[0].date
        var endDate =
          typeof $scope.rangeEnd !== "undefined" && $scope.rangeEnd
            ? new Date($scope.rangeEnd)
            : dataSorted[indexLast].date
        var minDate = angular.copy(dataSorted[0].date)
        var maxDate = angular.copy(dataSorted[indexLast].date)

        initPanel(startDate, endDate, minDate, maxDate, indexLast, dataSorted)
      })

      function initPanel(
        startDate,
        endDate,
        minDate,
        maxDate,
        indexLast,
        dataSorted
      ) {
        $scope.min = new Date(startDate.getFullYear() + "")
        $scope.max = new Date(endDate.getFullYear() + "")
        // endDate.setDate(endDate.getFullYear() + 1);

        $scope.data = new google.visualization.DataTable()
        $scope.data.addColumn("datetime", "start")
        $scope.data.addColumn("datetime", "end")
        $scope.data.addColumn("string", "content")
        $scope.data.addColumn("string", "id")

        $scope.options = {
          width: "100%",
          height: "400px",
          editable: false, // make the events dragable
          style: "box",
          box: { align: "left" },
          min: minDate,
          max: maxDate.setDate(maxDate.getFullYear() + 5),
          // zoomMin: -60000,
          // "zoomMax": 20,
          // 'intervalMax': 1000 * 60 * 60 * 24 * 90,
          showCurrentTime: false,
          zoomable: false,
          start: $scope.min,
          end: $scope.max
        }

        $scope.timeline = new links.Timeline(
          $element.find(".cf-timeline")[0],
          $scope.options
        )
        // $scope.timeline.setScale(links.Timeline.StepDate.SCALE.YEAR, 5);
        $scope.timeline.draw($scope.data)

        $scope.lengthRows = 0
        angular.forEach(dataSorted, function(item, i) {
          if (typeof item.attributes[$scope.langMap] !== "undefined") {
            var title =
              $filter("limitTo")(item.attributes[$scope.langMap].name, 72) +
              (item.attributes[$scope.langMap].name.length > 72 ? "..." : "")
          } else if (typeof item.attributes["en_US"] !== "undefined") {
            var title =
              $filter("limitTo")(item.attributes["en_US"].name, 72) +
              (item.attributes["en_US"].name.length > 72 ? "..." : "")
          } else {
            var title = ""
          }

          console.log("item.date", item.date)
          // if ( Object.prototype.toString.call(item) === "[object Date]" && !isNaN( item.getTime() ) ) {
          if (item.date) $scope.data.addRow([item.date, null, title, i + ""])
          // }
          $scope.lengthRows++
        })

        $timeout(function() {
          $scope.timeline.setVisibleChartRange(
            new Date(startDate),
            new Date(endDate)
          )
          $scope.timeline.redraw()
          $scope.selectLine(indexLast)
          adjustVisibleTimeRangeToAccommodateAllEvents()
        })

        createEvents(dataSorted)
      }

      function createEvents(dataSorted) {
        $window.google.visualization.events.addListener(
          $scope.timeline,
          "select",
          function() {
            var selItem = $scope.timeline.getSelection()[0]
            // gvDataTable.timeline.move(0.5)
            if (selItem) {
              var item = $scope.timeline.getItem(selItem.row)
              // console.log('The row selected ' + selItem.row, item);

              var phase = $scope.$root.$$phase
              console.log("$scope.$$phase", phase)
              if (!(phase == "$apply" || phase == "$digest")) {
                $scope.$apply(function() {
                  $scope.title = item.content
                  $scope.year = item.start.getFullYear()

                  $scope.itemPrev =
                    selItem.row > 0
                      ? $scope.timeline.getItem(selItem.row - 1)
                      : undefined
                  $scope.itemNext =
                    selItem.row < $scope.lengthRows - 1
                      ? $scope.timeline.getItem(selItem.row + 1)
                      : undefined
                  if (typeof $scope.itemPrev !== "undefined")
                    $scope.itemPrev.row = selItem.row - 1
                  if (typeof $scope.itemNext !== "undefined")
                    $scope.itemNext.row = selItem.row + 1

                  $scope.title =
                    dataSorted[selItem.row].attributes[$scope.langMap].name

                  $scope.id = dataSorted[selItem.row].id
                  $scope.title =
                    dataSorted[selItem.row].attributes[$scope.langMap].name
                  $scope.content =
                    dataSorted[selItem.row].attributes[
                      $scope.langMap
                    ].description
                  $scope.img = dataSorted[selItem.row].representations.large.url
                })
              } else {
                $scope.title = item.content
                $scope.year = item.start.getFullYear()
                $scope.itemPrev =
                  selItem.row > 0
                    ? $scope.timeline.getItem(selItem.row - 1)
                    : undefined
                $scope.itemNext =
                  selItem.row < $scope.lengthRows - 1
                    ? $scope.timeline.getItem(selItem.row + 1)
                    : undefined
                if (typeof $scope.itemPrev !== "undefined")
                  $scope.itemPrev.row = selItem.row - 1
                if (typeof $scope.itemNext !== "undefined")
                  $scope.itemNext.row = selItem.row + 1

                $scope.title =
                  dataSorted[selItem.row].attributes[$scope.langMap].name

                $scope.id = dataSorted[selItem.row].id
                $scope.title =
                  dataSorted[selItem.row].attributes[$scope.langMap].name
                $scope.content =
                  dataSorted[selItem.row].attributes[$scope.langMap].description
                $scope.img = dataSorted[selItem.row].representations.large.url
              }
              $scope.timeline.redraw()
            }
          }
        )
      }

      $scope.selectLine = function(line) {
        // $scope.timeline.redraw();
        $scope.timeline.setSelection([{ row: line }])
        $window.google.visualization.events.trigger(
          $scope.timeline,
          "select",
          {}
        )
      }

      $scope.zoom = zoom

      /**
       * Zoom
       * @param zoomVal
       */
      function zoom(zoomVal) {
        $scope.timeline.zoom(zoomVal)
        $scope.timeline.trigger("rangechange")
        $scope.timeline.trigger("rangechanged")
      }

      $scope.adjustVisibleTimeRangeToAccommodateAllEvents = adjustVisibleTimeRangeToAccommodateAllEvents
      /**
       * Adjust the visible time range such that all events are visible.
       */
      function adjustVisibleTimeRangeToAccommodateAllEvents() {
        // $scope.timeline.setVisibleChartRangeAuto();
        $scope.timeline.setVisibleChartRange($scope.min, $scope.max)
      }

      // Checks a string to see if it in a valid date format
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
      function isValidDate(s) {
        // format D(D)/M(M)/(YY)YY
        var ret = "",
          i
        var parse = s.split(" ")

        if (parse.length == 1) {
          i = parseInt(parse[0])
          if (i >= 1000 && i <= 3000) {
            ret += "January 1 " + parse[0]
          } else {
            ret = false
          }
        } else if (parse.length == 2) {
          i = parseInt(parse[1])
          if (i >= 1000 && i <= 3000) {
            if (inArray(parse[0], months)) {
              ret += parse[0]
            } else {
              ret += "January"
            }

            ret += " 1 " + parse[1]
          } else {
            ret = false
          }
        }

        return ret == "" ? s : ret
      }

      function inArray(needle, haystack) {
        var length = haystack.length
        for (var i = 0; i < length; i++) {
          if (haystack[i] == needle) return true
        }
        return false
      }
    }]
  }
}
canfTimeline.$inject = [
  "$window",
  "$rootScope",
  "$timeout",
  "$filter",
  "TheArchiveSrv"
]
canfTimelineDirective.directive("canfTimeline", canfTimeline)
