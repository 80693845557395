let canfTheArchiveDrtv = angular.module("canf.theArchiveDrtv", [
  "simplePagination"
])

// collectionsBanner
let collectionsBanner = function(
  $rootScope,
  $filter,
  $timeout,
  ComponentsSrv,
  Pagination
) {
  return {
    restrict: "E",
    templateUrl: "elements/collections-banner.html",
    replace: true,
    scope: {
      componentSrc: "=",
      map: "=",
      itemsPerPage: "=?limit",
      title: "=?",
      indexPage: "=?"
    },
    link: function($scope, $element, $attrs) {
      $timeout(function() {
        $scope.translation = $rootScope.translation
        $scope.menuActive = $rootScope.menuActive
      })
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.$watch("componentSrc", function() {
        $scope.component = ComponentsSrv.parseSidebarPosts(
          $scope.componentSrc,
          $scope.map
        )
        if ($scope.component) {
          if (typeof $attrs.order !== "undefined")
            $scope.component.posts = $filter("orderBy")(
              $scope.component.posts,
              $attrs.order,
              true
            )

          $scope.perPage =
            typeof $scope.itemsPerPage !== "undefined" ? $scope.itemsPerPage : 5
          $scope.pagination = Pagination.getNew($scope.perPage)
          $scope.pagination.numPages = Math.ceil(
            $scope.component.posts.length / $scope.pagination.perPage
          )

          if (typeof $scope.indexPage !== "undefined") {
            for (var i = 0; i < $scope.pagination.numPages; i++) {
              var start = $scope.pagination.perPage * i
              var end = start + $scope.pagination.perPage
              if ($scope.indexPage >= start && $scope.indexPage <= end) {
                $scope.pagination.page = i
              }
            }
          }
        }
      })
    }]
  }
}
collectionsBanner.$inject = [
  "$rootScope",
  "$filter",
  "$timeout",
  "ComponentsSrv",
  "Pagination"
]
canfTheArchiveDrtv.directive("collectionsBanner", collectionsBanner)

// collectionBanner
let collectionBanner = function($rootScope, $timeout) {
  return {
    restrict: "E",
    templateUrl: "elements/collection-banner.html",
    replace: true,
    scope: {
      title: "=cfTitle",
      slogan: "=cfSlogan",
      sloganContent: "=cfSloganContent",
      info: "=cfInfo",
      picture: "=cfPicture",
      link: "=cfLink"
    },
    link: function($scope, $element, $attrs) {
      $timeout(function() {
        $scope.translation = $rootScope.translation
      })
    }
  }
}
collectionBanner.$inject = ["$rootScope", "$timeout"]
canfTheArchiveDrtv.directive("collectionBanner", collectionBanner)
