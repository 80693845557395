let canfServices = angular.module("canf.services", [])

// MenuSrv
let MenuSrv = function($resource, RESOURCE_URL) {
  // var url = $rootScope.baseUrl + "services/getMenu/5";
  var url = RESOURCE_URL + "services/getMenu/:id"
  return $resource(
    url,
    {},
    {
      query: {
        method: window.isIE ? "JSONP" : "GET",
        cache: true,
        params: {},
        isArray: !1
      }
    }
  )
}
MenuSrv.$inject = ["$resource", "RESOURCE_URL"]
canfServices.factory("MenuSrv", MenuSrv)

// BlockSrv
let BlockSrv = function($resource, RESOURCE_URL, RESOURCE_CA_URL, SITE_URL) {
  // var lang = $cookies.get('lang') ? "/"+$cookies.get('lang'):'/en';
  var url = RESOURCE_URL + "services/getComponentsByBlock/:id/:lang"
  return $resource(
    url,
    {},
    {
      query: {
        method: "GET",
        cache: true,
        params: {},
        isArray: !1
      },
      preview: {
        url: RESOURCE_URL + "services/getComponentsWithPreview/:id/:lang",
        method: "GET",
        cache: true,
        params: {},
        isArray: !1
      },
      search: {
        // url: RESOURCE_SOLR_URL + "/canf/select?indent=on&q=:query&wt=json",
        url:
        SITE_URL + RESOURCE_CA_URL.interface +
          "?type=solr&q=:query&rows=:rows&start=:start",
        method: "GET",
        // headers: { "X-Requested-With": "XMLHttpRequest" },
        cache: true,
        params: {},
        isArray: !1
      }
    }
  )
}
BlockSrv.$inject = ["$resource", "RESOURCE_URL", "RESOURCE_CA_URL", "SITE_URL"]
canfServices.factory("BlockSrv", BlockSrv)

// TheArchiveSrv
let TheArchiveSrv = function($resource, SITE_URL, RESOURCE_CA_URL) {
  var url =  SITE_URL + RESOURCE_CA_URL
  return $resource(
    url,
    {},
    {
      find: {
        method: "GET",
        url: SITE_URL + RESOURCE_CA_URL.interface + "?type=find&q=:q",
        // headers: { 'Authorization': "Basic YWRtaW5pc3RyYXRvcjphZG1pbmFkbWlu" },
        cache: true,
        params: {},
        isArray: !1
      },
      detail: {
        method: "GET",
        url: SITE_URL + RESOURCE_CA_URL.interface + "?type=item&id=:id",
        cache: true,
        params: {},
        isArray: !1
      },
      collection: {
        method: "GET",
        url: SITE_URL + RESOURCE_CA_URL.interface + "?type=collection&id=:id", //"./json/get-collection-:id.json", //"http://54.83.36.5:85/cainterface.php?type=collection&id=:id",
        cache: true,
        params: {},
        isArray: !1
      },
      test: {
        method: "GET",
        url: "./json/get-collection-:id.json",
        cache: true,
        params: {},
        isArray: !1
      }
    }
  )
}
TheArchiveSrv.$inject = ["$resource", "SITE_URL", "RESOURCE_CA_URL"]
canfServices.factory("TheArchiveSrv", TheArchiveSrv)

// ThirdPartySrv
let ThirdPartySrv = function(
  $resource,
  RESOURCE_SALESFORCE_URL,
  RESOURCE_MAILCHIMP_URL
) {
  var url = RESOURCE_MAILCHIMP_URL
  return $resource(
    url,
    {},
    {
      sendNewsletter: {
        method: "GET",
        url: RESOURCE_MAILCHIMP_URL + "?email=:email",
        cache: false,
        params: {},
        isArray: !1
      },
      sendFormJoinUs: {
        method: window.isIE ? "JSONP" : "POST",
        url: RESOURCE_SALESFORCE_URL,
        cache: false,
        params: {},
        isArray: !1
      }
    }
  )
}
ThirdPartySrv.$inject = [
  "$resource",
  "RESOURCE_SALESFORCE_URL",
  "RESOURCE_MAILCHIMP_URL"
]
canfServices.factory("ThirdPartySrv", ThirdPartySrv)

//TranslationSrv
let TranslationSrv = function($resource) {
  return $resource(
    "translations/:lang.json",
    { lang: "@lang" },
    {
      get: {
        cache: true
      }
    }
  )
}
TranslationSrv.$inject = ["$resource"]
canfServices.factory("TranslationSrv", TranslationSrv)

// ComponentsSrv
let ComponentsSrv = function(
  $rootScope,
  $filter,
  Slug,
  $resource,
  RESOURCE_URL
) {
  var ComponentsSrv = new Object()

  function parseComponentDataElement(post, data, mapParse) {
    angular.forEach(data, function(element) {
      switch (element.name) {
        case mapParse.title:
          post.title = element.value
          break
        case mapParse.img:
          post.img = element.value
          break
        case mapParse.summary:
          post.summary = element.value
          break
        case mapParse.description:
          post.description = element.value
          break
        case mapParse.attachment:
          post.attachment = element.value
          break
        case mapParse.collID:
          post.collID = element.value
          break
        case mapParse.date:
          post.date = element.value.length > 0 ? new Date(element.value) : false
          break
        case mapParse.featured:
          post.featured = element.value == "" ? "0" : element.value
        case mapParse.author:
          post.author = element.value
          break
      }
    })
    return post
  }

  ComponentsSrv.buildURL = function(baseUrl, item, post) {
    let url
    if (baseUrl) {
      url = baseUrl + item.id + "-" + Slug.slugify(post.title)
    } else {
      url =
        "./" +
        $rootScope.menuActive.url +
        "/" +
        $rootScope.menuActive.childrenActive.url +
        "/" +
        item.id +
        "-" +
        Slug.slugify(post.title)
    }
    return url
  }

  ComponentsSrv.find = function(components, componentID, elementID) {
    if (typeof components === "undefined" || !components.length) {
      return false
    }
    var component = $filter("filter")(components, function(i) {
      return i.id === componentID
    })
    component = typeof component[0] !== "undefined" ? component[0] : null

    if (!component) {
      return null
    }

    if (typeof elementID !== "undefined" && elementID !== false) {
      var item = $filter("filter")(component.data, function(i) {
        return i.id === elementID
      })
      item = typeof item[0] !== "undefined" ? item[0] : null
    } else {
      var item = component
    }

    return item
  }

  ComponentsSrv.findChildComponent = function(components, childComponentID) {
    var childComponent
    angular.forEach(components, function(item) {
      if (typeof item.child_component !== "undefined") {
        if (childComponentID != 0) {
          var component = $filter("filter")(item.child_component, function(
            i,
            index
          ) {
            i.index = index
            return i.id === childComponentID
          })
          if (
            component &&
            Array.isArray(component) &&
            typeof component[0] !== "undefined"
          ) {
            childComponent = component[0]
          }
        } else {
          childComponent =
            typeof item.child_component[0] !== "undefined"
              ? item.child_component[0]
              : false
        }
      }
    })

    return childComponent
  }

  ComponentsSrv.parseChildComponent = function(childComponent, mapParse) {
    if (!childComponent) return false

    var post = new Object()
    post.index = childComponent.index

    post = parseComponentDataElement(post, childComponent.data, mapParse)

    return post
  }

  ComponentsSrv.parseSidebarPosts = function(
    component,
    mapParse,
    baseUrl,
    filter
  ) {
    if (!component) return false

    // var component = ComponentsSrv.find(components, componentID);
    var componentParsed = new Object()
    componentParsed.title =
      typeof component.data[0] !== "undefined" &&
      typeof component.data[0].name !== "undefined"
        ? component.data[0].name
        : component.name
    componentParsed.posts = new Array()

    var childElements =
      typeof filter !== "undefined" && filter
        ? $filter("filter")(component.child_component, filter)
        : component.child_component
    angular.forEach(childElements, function(item, key) {
      var post = new Object()
      post.id = item.id
      post.index = key
      post = parseComponentDataElement(post, item.data, mapParse)

      post.url = ComponentsSrv.buildURL(baseUrl, item, post)

      componentParsed.posts.push(post)
    })

    return componentParsed
  }

  ComponentsSrv.parseNewsfeeds = function(component, mapParse, baseUrl) {
    if (!component) return false

    var componentParsed = new Object()
    componentParsed.title =
      typeof component.data[0] !== "undefined" &&
      typeof component.data[0].name !== "undefined"
        ? component.data[0].name
        : component.name
    componentParsed.posts = new Array()
    angular.forEach(component.child_component, function(item, key) {
      var post = new Object()
      post = parseComponentDataElement(post, item.data, mapParse)

      post.url = baseUrl + item.id + "-" + Slug.slugify(post.title) //{{ menuActive.url }}/{{ item.url }}/{{ item.slug }}
      componentParsed.posts.push(post)
    })

    return componentParsed
  }

  ComponentsSrv.findComponentsDatasElement = function(id) {
    var url = RESOURCE_URL + "componentsDatasElements/view/:id"
    return $resource(
      url,
      {},
      {
        query: {
          method: "GET",
          cache: true,
          params: {}
        }
      }
    ).get({ id: id }).$promise
  }

  ComponentsSrv.findComponentRemote = function(id, lang, data) {
    var url = RESOURCE_URL + "components/viewJson/:id/:lang/:children"
    var query = { id: id, lang: lang }

    if (data && data.children) {
      query.children = data.children
    }

    return $resource(
      url,
      {},
      {
        query: {
          method: "GET",
          cache: true,
          params: {}
        }
      }
    ).get(query).$promise
  }

  ComponentsSrv.findComponentDataElementSiblings = function(id) {
    var url = RESOURCE_URL + "componentsDatasElements/siblings/:id"
    return $resource(
      url,
      {},
      {
        query: {
          method: "GET",
          cache: true,
          params: {}
        }
      }
    ).get({ id: id }).$promise
  }

  ComponentsSrv.getMapPost = function() {
    return {
      title: "Post Title",
      img: "Upload File",
      attachment: "Upload PDF",
      description: "Description",
      author: "Author",
      date: "Created on"
    }
  }

  return ComponentsSrv
}
ComponentsSrv.$inject = [
  "$rootScope",
  "$filter",
  "Slug",
  "$resource",
  "RESOURCE_URL"
]
canfServices.factory("ComponentsSrv", ComponentsSrv)

// PagesLoadedSrv
let PagesLoadedSrv = function() {
  var PagesLoadedSrv = {}

  return PagesLoadedSrv
}
canfServices.factory("PagesLoadedSrv", PagesLoadedSrv)

// PagesSrv
let PagesSrv = function($templateCache, $location) {
  var PagesSrv = {}

  PagesSrv.checkTemplateExists = function(block) {
    if (
      typeof block.menu !== "undefined" &&
      typeof block.menu.childrenActive === "undefined"
    ) {
      PagesSrv.blockId = block.menu.block_id
      PagesSrv.pageTpl = "pages/" + block.menu.url + "/index.html"
    } else if (typeof block.menu !== "undefined") {
      PagesSrv.blockId = block.menu.childrenActive.block_id
      PagesSrv.pageTpl =
        "pages/" +
        block.menu.url +
        "/" +
        block.menu.childrenActive.url +
        ".html"
    }

    if (!$templateCache.get(PagesSrv.pageTpl)) {
      console.log("TPLTPL", $templateCache.get(PagesSrv.pageTpl))
      $location.path("/404")
      throw new Error("Template does not exist")
    }
  }

  return PagesSrv
}
PagesSrv.$inject = ["$templateCache", "$location"]
canfServices.factory("PagesSrv", PagesSrv)

// responsiveHelper
let responsiveHelper = function($window, $rootScope) {
  // Gather winWidth based in Twitter BootStrap http://getbootstrap.com/css/#grid-media-queries
  $rootScope.responsiveHelper = new Object()
  var responsiveHelper = $rootScope.responsiveHelper
  var winWidth = $window.innerWidth || $window.outerWidth

  responsiveHelper.setScreenSize = function(winWidth) {
    responsiveHelper.winWidth = winWidth
    responsiveHelper.isXs = winWidth < 768
    responsiveHelper.isSm = winWidth >= 768 && winWidth < 992
    responsiveHelper.isMd = winWidth >= 992 && winWidth < 1200
    responsiveHelper.isLg = winWidth >= 1200

    if (responsiveHelper.isLg) {
      responsiveHelper.screenSize = "lg"
    } else if (responsiveHelper.isMd) {
      responsiveHelper.screenSize = "md"
    } else if (responsiveHelper.isSm) {
      responsiveHelper.screenSize = "sm"
    } else if (responsiveHelper.isXs) {
      responsiveHelper.screenSize = "xs"
    }
  }

  angular.element($window).bind("resize", function() {
    var winWidth = $window.innerWidth || $window.outerWidth
    $rootScope.$apply(function() {
      responsiveHelper.setScreenSize(winWidth)
      // console.log( "Responsive", responsiveHelper.screenSize );
    })
  })

  responsiveHelper.setScreenSize(winWidth)
  return responsiveHelper
}
responsiveHelper.$inject = ["$window", "$rootScope"]
canfServices.factory("responsiveHelper", responsiveHelper)
