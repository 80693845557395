let componentSingleNavPosts = function($rootScope) {
  return {
    restrict: "E",
    templateUrl: "components/nav-posts-single-post.html",
    replace: true,
    scope: {
      siblings: "="
    },
    controller: ["$scope", function($scope) {
      $scope.translation = $rootScope.translation
    }]
  }
}

angular
  .module("canf.componentSingleNavPostsDirective", [])
  .directive("componentSingleNavPosts", ["$rootScope", componentSingleNavPosts])
