let canfPage = angular.module("canf.page", [
  "canf.canfTimelineDirective",
  "canf.componentNewsfeedDirective",
  "canf.componentRecentBlogsDirective",
  "canf.componentFeaturedArticleDirective",
  "canf.componentSidebarmenuDirective",
  "canf.componentSidebarPostsDirective",
  "canf.componentNavPostsDirective",
  "canf.componentSingleNavPostsDirective",
  "canf.componentSimpleHtmlDirective",
  "canf.sidebarmenuDirective",
  "canf.componentListContent",
  "canf.formDonate",
  "canf.formJoinus"
])

let PageCtrl = function(
  $rootScope,
  $scope,
  $location,
  BlockSrv,
  block,
  PagesLoadedSrv,
  PagesSrv,
  ComponentsSrv
) {
  $scope.href = $location.absUrl()
  PagesSrv.checkTemplateExists(block)
  $scope.pageTpl = PagesSrv.pageTpl

  $rootScope.menuActive = block.menu
  $rootScope.metaTitle =
    typeof block.menu.childrenActive !== "undefined"
      ? $rootScope.menuActive.childrenActive.title +
        " | " +
        $rootScope.menuActive.title
      : $rootScope.menuActive.title
  $rootScope.metaUrl = $location.absUrl()

  if (typeof PagesLoadedSrv[PagesSrv.blockId] === "undefined") {
    $rootScope.hideLoader = false
  }

  $scope.itemID = false
  if (typeof block.itemID !== "undefined") {
    $scope.itemID =
      block.itemID !== "first-item" ? parseInt(block.itemID.split("-")[0]) : 0
  }

  if ($scope.itemID) {
    loadPost($rootScope.lang)
  } else {
    loadBlock($rootScope.lang)
  }

  function loadBlock(lang) {
    var service = "query"
    if ($rootScope.isPreview) {
      service = "preview"
    }

    BlockSrv.loaded = BlockSrv[service](
      {
        id: PagesSrv.blockId,
        lang: lang
      },
      loadedBlock
    )
  }

  function loadPostError(error) {
    console.log(error)
  }

  function loadPost(lang) {
    $scope.components = []
    ComponentsSrv.findComponentsDatasElement($scope.itemID)
      .then(function(respond) {
        $scope.components.push(respond.componentsDatasElement.component)
        respond.componentsDatasElement.index = 0
        setPost(respond.componentsDatasElement)

        loadFinished()
      })
      .catch(loadPostError)

    ComponentsSrv.findComponentRemote(109, lang)
      .then(function(respond) {
        $scope.components.push(respond.component)
      })
      .catch(loadPostError)

    ComponentsSrv.findComponentRemote(110, lang, {
      children: 4
    })
      .then(function(respond) {
        $scope.components.push(respond.component)
      })
      .catch(loadPostError)

    ComponentsSrv.findComponentDataElementSiblings($scope.itemID).then(function(
      respond
    ) {
      const siblings = respond.siblings

      let index = 0
      for (var siblingName in siblings) {
        const sibling = siblings[siblingName]
        if (sibling.data.length) {
          sibling.index = index++
          let post = ComponentsSrv.parseChildComponent(sibling, getMapPost())
          post.url = ComponentsSrv.buildURL("/updates/blog/", sibling, post)
          siblings[siblingName] = post
        } else {
          siblings[siblingName] = false
        }
      }
      $scope.siblings = siblings
    })
  }

  var nRequests = 0
  function loadedBlock(response) {
    $scope.components = response.result

    if ($scope.itemID) {
      $scope.childComponent = ComponentsSrv.findChildComponent(
        $scope.components,
        $scope.itemID
      )
      if ($scope.childComponent) {
        setPost($scope.childComponent)
      }

      if (!$scope.postActive) {
        if ($rootScope.lang == "en") {
          if (nRequests == 0) {
            nRequests++
            loadBlock("es")
          }
        } else if ($rootScope.lang == "es") {
          if (nRequests == 0) {
            nRequests++
            loadBlock("en")
          }
        } else {
          $location.path(
            "/" + block.menu.url + "/" + block.menu.childrenActive.url
          )
        }
      }
    }

    loadFinished()
    PagesLoadedSrv[PagesSrv.blockId] = true
  }

  function setPost(loadedPost) {
    $scope.postActive = ComponentsSrv.parseChildComponent(
      loadedPost,
      getMapPost()
    )
    $scope.postActive.id = loadedPost.id
    $rootScope.metaTitle =
      $scope.postActive.title + " - " + $rootScope.menuActive.title

    $rootScope.publishedDate = $scope.postActive.date
  }

  function loadFinished() {
    $rootScope.$emit("pageLoaded")
    $rootScope.hideLoader = true
  }

  function getMapPost() {
    return ComponentsSrv.getMapPost()
  }
}

PageCtrl.$inject = [
  "$rootScope",
  "$scope",
  "$location",
  "BlockSrv",
  "block",
  "PagesLoadedSrv",
  "PagesSrv",
  "ComponentsSrv"
]

canfPage.controller("PageCtrl", PageCtrl)
