let canfRoutes = angular.module("canf.routes", [])

let router = function($routeProvider, $locationProvider, $httpProvider) {
  $httpProvider.defaults.useXDomain = !0
  delete $httpProvider.defaults.headers.common["X-Requested-With"]
  $routeProvider
    .when("/404", {
      template: require("Templates/pages/tplNotFound.html"),
      controller: "AppCtrl"
    })
    .when("/", {
      template: require("Templates/home.html"),
      controller: "HomeCtrl",
      resolve: {
        block: ["$q", "$filter", "MenuSrv", function($q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                i.isActive = i.id === 11 || i.id === 40 ? true : false
                return i.id === 11 || i.id === 40
              })[0]
            }
          })
        }]
      }
    })
    .when("/home", {
      template: require("Templates/home.html"),
      controller: "HomeCtrl",
      resolve: {
        block: ["$q", "$filter", "MenuSrv", function($q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                i.isActive = i.id === 11 ? true : false
                return i.id === 11
              })[0]
            }
          })
        }]
      }
    })
    .when("/search", {
      template: require("Templates/search-results.html"),
      controller: "SearchCtrl",
      resolve: {
        block: ["$q", "$route", "$filter", "MenuSrv", function($q, $route, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              query: $route.current.params.query
            }
          })
        }]
      }
    })
    .when("/search/:query", {
      template: require("Templates/search-results.html"),
      controller: "SearchCtrl",
      resolve: {
        block: ["$q", "$route", "$filter", "MenuSrv", function($q, $route, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              query: $route.current.params.query
            }
          })
        }]
      }
    })
    .when("/about-us", {
      template: require("Templates/pages/about-us/index.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "about-us") {
                  i.childrenActive = undefined
                  return i
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/about-us/:slug", {
      template: require("Templates/pages/about-us/index.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "about-us") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.slug ? true : false
                      return si.url == $route.current.params.slug
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/our-work", {
      redirectTo: "/our-work/cuba-based-projects"
    })
    .when("/our-work/cuba-based-projects", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "our-work") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == "cuba-based-projects" ? true : false
                      return si.url == "cuba-based-projects"
                    }
                  )[0])
                }
              })[0]
              // itemID: "first-item"
            }
          })
        }]
      }
    })
    .when("/our-work/:slug", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "our-work") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.slug ? true : false
                      return si.url == $route.current.params.slug
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/updates", {
      redirectTo: "/updates/blog"
    })
    .when("/updates/:slug", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "updates") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.slug ? true : false
                      return si.url == $route.current.params.slug
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/the-archive", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  i.childrenActive = undefined
                  return i
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/the-archive/timeline", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive = si.url == "timeline" ? true : false
                      return si.url == "timeline"
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/the-archive/archive-search", {
      template: require("Templates/pages/the-archive/archive-search.html"),
      controller: "TheArchiveCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive = si.url == "archive-search" ? true : false
                      return si.url == "archive-search"
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/the-archive/archive-search/:query", {
      template: require("Templates/pages/the-archive/archive-search.html"),
      controller: "TheArchiveCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive = si.url == "archive-search" ? true : false
                      return si.url == "archive-search"
                    }
                  )[0])
                }
              })[0],
              query: $route.current.params.query
            }
          })
        }]
      }
    })
    .when("/the-archive/archive-search/:query/:itemID", {
      template: require("Templates/pages/the-archive/archive-search.html"),
      controller: "TheArchiveCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive = si.url == "archive-search" ? true : false
                      return si.url == "archive-search"
                    }
                  )[0])
                }
              })[0],
              itemID: $route.current.params.itemID,
              query: $route.current.params.query
            }
          })
        }]
      }
    })
    .when("/the-archive/special-collections/:collID", {
      template: require("Templates/pages/the-archive/special-collections-detail.html"),
      controller: "TheArchiveSpecialCollsCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == "special-collections" ? true : false
                      return si.url == "special-collections"
                    }
                  )[0])
                }
              })[0],
              collID: $route.current.params.collID
            }
          })
        }]
      }
    })
    .when("/the-archive/special-collections/:collID/:itemID", {
      template: require("Templates/pages/the-archive/special-collections-object-detail.html"),
      controller: "TheArchiveSpecialCollsCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == "special-collections" ? true : false
                      return si.url == "special-collections"
                    }
                  )[0])
                }
              })[0],
              collID: $route.current.params.collID,
              itemID: $route.current.params.itemID
            }
          })
        }]
      }
    })
    .when("/the-archive/:slug", {
      template: require("Templates/the-archive.html"),
      templateUrl: "the-archive.html",
      controller: "TheArchiveCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "the-archive") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.slug ? true : false
                      return si.url == $route.current.params.slug
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/get-involved", {
      redirectTo: "/get-involved/volunteer-intern"
    })
    .when("/get-involved/:slug", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == "get-involved") {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.slug ? true : false
                      return si.url == $route.current.params.slug
                    }
                  )[0])
                }
              })[0]
            }
          })
        }]
      }
    })
    .when("/es", {
      redirectTo: "/"
    })
    .when("/en", {
      redirectTo: "/"
    })
    .when("/es/:slug", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        if (search && search.lang) {
          path += "?lang=" + search.lang
        }
        return path
      }
    })
    .when("/en/:slug", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        if (search && search.lang) {
          path += "?lang=" + search.lang
        }
        return path
      }
    })
    .when("/es/:slug/:slug2", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        if (search && search.lang) {
          path += "?lang=" + search.lang
        }
        return path
      }
    })
    .when("/en/:slug/:slug2", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        if (search && search.lang) {
          path += "?lang=" + search.lang
        }
        return path
      }
    })
    .when("/es/:slug/:slug2/:slug3", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        if (search && search.lang) {
          path += "?lang=" + search.lang
        }
        return path
      }
    })
    .when("/en/:slug/:slug2/:slug3", {
      redirectTo: function(routeParams, path, search) {
        var path = path.substring(3)
        return path
      }
    })
    .when("/:slug", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$q", "$filter", "MenuSrv", function($q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            return {
              menu: $filter("filter")(results[0].result, function(i) {
                i.isActive = i.id === $route.current.params.slug ? true : false
                return i.id === $route.current.params.slug
              })[0]
            }
          })
        }]
      }
    })
    .when("/:slug/:category/:itemID", {
      template: require("Templates/page1.html"),
      controller: "PageCtrl",
      resolve: {
        block: ["$route", "$q", "$filter", "MenuSrv", function($route, $q, $filter, MenuSrv) {
          return $q.all([MenuSrv.loaded.$promise]).then(function(results) {
            var response = {
              menu: $filter("filter")(results[0].result, function(i) {
                if (i.url == $route.current.params.slug) {
                  return (i.childrenActive = $filter("filter")(
                    i.children,
                    function(si) {
                      si.isActive =
                        si.url == $route.current.params.category ? true : false
                      return si.url == $route.current.params.category
                    }
                  )[0])
                }
              })[0],
              itemID: $route.current.params.itemID
            }
            return response
          })
        }]
      }
    })
    .otherwise({
      redirectTo: function(routeParams, path, search) {
        if (path.indexOf("/en") >= 0) {
          var path = path.substring(3)
        } else if (path.indexOf("/es") >= 0) {
          var path = path.substring(3)
        } else {
          var path = "/404"
        }

        return path
      }
    }),
    $locationProvider.html5Mode(!0),
    $locationProvider.hashPrefix("!")
}

router.$inject = ["$routeProvider", "$locationProvider", "$httpProvider"]

canfRoutes.config(router)
