angular
  .module("canf.sidebarmenuDirective", [])
  .directive("sidebarmenu", function() {
    return {
      restrict: "E",
      templateUrl: "elements/sidebarmenu.html",
      replace: true,
      scope: {
        title: "=bbTitle",
        list: "=bbList",
        itemActive: "=bbItemactive",
        baseurl: "=bbBaseurl",
        image: "=?bbImage",
        hideImg: "=?bbHideimg"
      }
    }
  })
