let canfComponentNavPostsDirective = angular.module(
  "canf.componentNavPostsDirective",
  ["simplePagination"]
)

// componentNavPosts
let componentNavPosts = function(
  $rootScope,
  $filter,
  ComponentsSrv,
  Pagination
) {
  return {
    restrict: "E",
    templateUrl: "components/nav-posts.html",
    replace: true,
    scope: {
      componentSrc: "=",
      map: "=",
      itemsPerPage: "=?limit",
      indexPage: "=?"
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.translation = $rootScope.translation

      $scope.$watch("componentSrc", function() {
        var indexPage
        var baseUrl =
          typeof $attrs.baseUrl !== "undefined" ? $attrs.baseUrl : false
        $scope.component = ComponentsSrv.parseSidebarPosts(
          $scope.componentSrc,
          $scope.map,
          baseUrl
        )
        if ($scope.component) {
          if (typeof $attrs.order !== "undefined") {
            $scope.component.posts = $filter("orderBy")(
              $scope.component.posts,
              $attrs.order,
              true
            )
            angular.forEach($scope.component.posts, function(item, index) {
              if (item.index == $scope.indexPage) {
                indexPage = index
              }
            })
          } else {
            var indexPage = $scope.indexPage
          }

          $scope.perPage =
            typeof $scope.itemsPerPage !== "undefined" ? $scope.itemsPerPage : 1
          $scope.pagination = Pagination.getNew($scope.perPage)
          $scope.pagination.numPages = Math.ceil(
            $scope.component.posts.length / $scope.pagination.perPage
          )

          if (typeof $scope.indexPage !== "undefined") {
            for (var i = 0; i < $scope.pagination.numPages; i++) {
              var start = $scope.pagination.perPage * i
              var end = start + $scope.pagination.perPage
              if (indexPage >= start && indexPage <= end) {
                $scope.pagination.page = i
              }
            }
          }
        }
      })

      $scope.prev = function() {
        console.log("$scope.indexPage", $scope.indexPage)
      }

      $scope.next = function() {
        console.log("$scope.indexPage", $scope.indexPage)
      }
    }]
  }
}
componentNavPosts.$inject = [
  "$rootScope",
  "$filter",
  "ComponentsSrv",
  "Pagination"
]
canfComponentNavPostsDirective.directive("componentNavPosts", componentNavPosts)
