let canfSocialShare = angular.module("canf.socialShare", [])

// socialShare
let socialShare = function($rootScope, $window, $location) {
  return {
    restrict: "A",
    replace: true,
    scope: {},
    link: function($scope, $element, $attrs) {
      console.log("Share", $attrs.type)
      $element.click(function(e) {
        var absUrl = $location.absUrl()
        switch ($attrs.type) {
          case "fb":
            var urlShare =
              "https://www.facebook.com/sharer/sharer.php?s=100&p[url]=" +
              absUrl +
              "&p[title]=" +
              $rootScope.metaTitle +
              "&p[summary]=" +
              $rootScope.metaTitle
            $window.open(urlShare, "Share on Facebook", "width=650,height=400")
            break
          case "tw":
            var urlShare =
              "https://twitter.com/intent/tweet?original_referer=" +
              absUrl +
              "&via=voiceofcanf&text=" +
              $rootScope.metaTitle +
              "&tw_p=tweetbutton&url=" +
              absUrl
            $window.open(urlShare, "Share on Twitter", "width=650,height=400")
            break
        }
      })
    }
  }
}
socialShare.$inject = ["$rootScope", "$window", "$location"]
canfSocialShare.directive("socialShare", socialShare)
