let canfComponentListContent = angular.module("canf.componentListContent", [
  "simplePagination"
])

// componentListContent
let componentListContent = function(
  $rootScope,
  $filter,
  $timeout,
  ComponentsSrv,
  Pagination
) {
  return {
    restrict: "E",
    templateUrl: "components/list-content.html",
    replace: true,
    scope: {
      componentSrc: "=",
      map: "=",
      itemsPerPage: "=?limit",
      title: "=?",
      indexPage: "=?"
    },
    link: function($scope, $element, iAttrs) {
      $timeout(function() {
        $scope.translation = $rootScope.translation
      })
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.$watch("componentSrc", function() {
        $scope.component = ComponentsSrv.parseSidebarPosts(
          $scope.componentSrc,
          $scope.map
        )
        console.log($scope.component)
        if ($scope.component) {
          if (typeof $attrs.order !== "undefined")
            $scope.component.posts = $filter("orderBy")(
              $scope.component.posts,
              $attrs.order,
              true
            )

          $scope.perPage =
            typeof $scope.itemsPerPage !== "undefined" ? $scope.itemsPerPage : 5
          $scope.pagination = Pagination.getNew($scope.perPage, 3)
          $scope.pagination.numPages = Math.ceil(
            $scope.component.posts.length / $scope.pagination.perPage
          )

          if (typeof $scope.indexPage !== "undefined") {
            for (var i = 0; i < $scope.pagination.numPages; i++) {
              var start = $scope.pagination.perPage * i
              var end = start + $scope.pagination.perPage
              if ($scope.indexPage >= start && $scope.indexPage <= end) {
                $scope.pagination.page = i
              }
            }
          }
        }
      })
    }]
  }
}
componentListContent.$inject = [
  "$rootScope",
  "$filter",
  "$timeout",
  "ComponentsSrv",
  "Pagination"
]
canfComponentListContent.directive("componentListContent", componentListContent)
