let BBDirectives = angular.module("BBDirectives", [])

// bbPopup
let bbPopup = function($document, $templateCache, $timeout, $compile) {
  return {
    restrict: "A",
    replace: false,
    scope: {
      title: "@?",
      content: "=",
      functions: "=?",
      offset: "=?"
    },
    transclude: true,
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $transclude(function(clone) {
        $element.find(".popover").remove()
        angular.element(clone[0]).appendTo($element)
      })
    }],
    compile: function($element, $attrs, $transclude) {
      return function($scope, $element, $attrs) {
        var tplPopover =
          '<div class="popup cf-fade" style="display: block; opacity: 1;" ng-show="show" ng-style="stylePopover"> <button type="button" class="close" ng-click="show = false;"><span>&times;</span></button> <div class="popover-content">' +
          $templateCache.get($attrs.template) +
          "</div> </div>"
        var popover = $compile(tplPopover)($scope)

        $timeout(function() {
          $element.on("click", function(event) {
            event.preventDefault()
            $scope.$apply(function() {
              $scope.show = !$scope.show
            })

            // $document.find('body').find('.popover').remove()
            if (!$scope.stylePopover) $document.find("body").append(popover)
            var pos = $(this).offset()
            var top =
              pos.top -
              $(popover[0]).height() -
              (typeof $scope.offset !== "undefined" ? $scope.offset : 0)
            var left = $(popover[0]).width() / 2

            $scope.$apply(function() {
              $scope.stylePopover = {
                top: top + 5,
                left: "50%",
                marginLeft: -1 * left
              }
            })

            $("html, body").animate(
              { scrollTop: $(".popup").offset().top - 5 },
              "slow"
            )
          })

          $document.find("body").bind("mouseup", function(e) {
            // console.log("Mouseup", $(popover), e)
            var container = $(popover, $element)

            if (
              !container.is(e.target) && // if the target of the click isn't the container...
              container.has(e.target).length === 0
            ) {
              // ... nor a descendant of the container
              // $('.filter-options').slideUp('fast');
              $scope.$apply(function() {
                $scope.show = false
              })
            }
          })
        })
      }
    }
  }
}
bbPopup.$inject = ["$document", "$templateCache", "$timeout", "$compile"]
BBDirectives.directive("bbPopup", bbPopup)

// bbPopover
let bbPopover = function($document, $timeout, $compile) {
  return {
    restrict: "A",
    replace: false,
    scope: {
      title: "@?",
      content: "=",
      functions: "=?"
    },
    transclude: true,
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $transclude(function(clone) {
        $element.find(".popover").remove()
        angular.element(clone[0]).appendTo($element)
      })
    }],
    compile: function($element, $attrs, $transclude) {
      return function($scope, $element, $attrs) {
        var tplPopover =
          '<div class="popover bottom" style="display: block; opacity: 1;" ng-show="show" ng-style="stylePopover"> <div class="arrow"></div><button type="button" class="close" ng-click="show = false;"><span>&times;</span></button> <h3 class="popover-title" ng-bind="title"></h3> <div class="popover-content">' +
          $scope.content +
          "</div> </div>"
        var popover = $compile(tplPopover)($scope)

        $timeout(function() {
          $element.on("click", function(event) {
            $scope.$apply(function() {
              $scope.show = !$scope.show
            })

            // $document.find('body').find('.popover').remove()
            if (!$scope.stylePopover) $document.find("body").append(popover)
            var pos = $(this).offset()
            var top = pos.top + $(this).height()
            var left =
              pos.left + $(this).width() / 2 - $(popover[0]).width() / 2

            $scope.$apply(function() {
              $scope.stylePopover = { top: top + 5, left: left + 2 }
            })
          })

          $document.find("body").bind("mouseup", function(e) {
            // console.log("Mouseup", $(popover), e)
            var container = $(popover, $element)

            if (
              !container.is(e.target) && // if the target of the click isn't the container...
              container.has(e.target).length === 0
            ) {
              // ... nor a descendant of the container
              // $('.filter-options').slideUp('fast');
              $scope.$apply(function() {
                $scope.show = false
              })
            }
          })
        })
      }
    }
  }
}
bbPopover.$inject = ["$document", "$timeout", "$compile"]
BBDirectives.directive("bbPopover", bbPopover)

// bbModalAction
let bbModalAction = function($document, $timeout, $compile) {
  return {
    restrict: "A",
    replace: false,
    scope: {
      title: "@?",
      content: "="
    },
    // transclude: true,
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.close = function() {
        $scope.htmlPopup.remove()
        $scope.shadowPopup.remove()
        $document.find("body").removeClass("hide-scroll")
      }
    }],
    link: function($scope, $element, $attrs) {
      var shadowPopup = '<div class="popup-shadow"></div>'
      var tplPopup =
        '<div class="popup raw cf-fade" style="display: block; opacity: 1;" ng-style="stylePopover">' +
        '<button type="button" class="close close-button-inner" ng-click="close(); $event.stopPropagation()">' +
        "<span>&times;</span>" +
        "</button>" +
        '<div class="popover-content">' +
        '<div class="popover-inner">' +
        '<div class="embed-responsive embed-responsive-16by9">' +
        $scope.content +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>"
      $scope.htmlPopup = $compile(tplPopup)($scope)
      $scope.shadowPopup = $compile(shadowPopup)($scope)

      $element.on("click", function(event) {
        event.preventDefault()

        $timeout(function() {
          $document
            .find("body")
            .addClass("hide-scroll")
            .append($scope.shadowPopup)
            .append($scope.htmlPopup)
          var mTop = $($scope.htmlPopup[0]).height() / 2
          var mLeft = $($scope.htmlPopup[0]).width() / 2

          $scope.$apply(function() {
            $scope.stylePopover = {
              top: "50%",
              left: "50%",
              marginLeft: -mLeft,
              marginTop: -mTop
            }
          })
        })
      })

      $document.find("body").bind("mouseup", function(e) {
        var container = $($scope.htmlPopup, $element)
        var btnClose = $scope.htmlPopup.find(".close")

        if (btnClose.is(e.target) || btnClose.has(e.target).length > 0) {
          $scope.close()
        } else if (
          !container.is(e.target) && // if the target of the click isn't the container...
          container.has(e.target).length === 0
        ) {
          // ... nor a descendant of the container
          $scope.close()
        }
      })
    }
  }
}
bbModalAction.$inject = ["$document", "$timeout", "$compile"]
BBDirectives.directive("bbModalAction", bbModalAction)
