angular
  .module("canf.componentSimpleHtmlDirective", [])
  .directive("componentSimpleHtml", function() {
    return {
      restrict: "E",
      templateUrl: "components/simple-html.html",
      replace: true,
      scope: {
        component: "="
      }
    }
  })
