var site_url = SITE_URL || "http://canf.org/"
var cms_url = CMS_URL || "http://cms.canf.org/"

export default angular
  .module("canf.config", [])
  .constant("SITE_URL", site_url)
  .constant("RESOURCE_URL", cms_url) //http://54.83.36.5:90/ //http://canf.org:81/
  .constant("RESOURCE_CA_URL", { interface: "cainterface.php" }) //interface: "http://canf.org/cainterface.php", timeline: "http://canf.org/service.php/timelineServices/TimeLine/get"
  .constant("RESOURCE_SOLR_URL", "http://canf.org:8983/solr")
  .constant("RESOURCE_MAILCHIMP_URL", site_url + "/mailchimp-subscribe.php")
  .constant("RESOURCE_SALESFORCE_URL", "#")
  .constant("RESOURCE_IIPSRV", {
    path: "/data/canf/providence/media/canf/images/zoom/",
    url: "https://canf.org/iipsrv/iipsrv.fcgi"
  })
  .constant("SOCIAL_COUNTER", {
    domain: site_url, //canf.brangerbriz.com //http://canf.org/
    curl_layer: "./php_scripts/sharethis.php", //http://canf.brangerbriz.com/php_scripts/sharethis.php //http://localhost:8080/sharethis.php
    cache_layer: "./php_scripts/facebook.php",
    facebook: "https://graph.facebook.com/"
  })
