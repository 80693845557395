angular
  .module("canf.componentFeaturedArticleDirective", [])
  .directive("componentFeaturedArticle", function() {
    return {
      restrict: "E",
      templateUrl: "components/featured-article.html",
      replace: true,
      scope: {
        component: "="
      }
    }
  })
