let canfHome = angular.module("canf.home", [
  "canf.componentNewsfeedDirective",
  "canf.componentRecentBlogsDirective",
  "canf.componentFeaturedArticleDirective",
  "canf.componentSidebarmenuDirective",
  "canf.componentSidebarPostsDirective"
])

let HomeCtrl = function(
  $rootScope,
  $scope,
  block,
  BlockSrv,
  PagesLoadedSrv,
  ComponentsSrv
) {
  $rootScope.menuActive = block.menu
  $rootScope.metaTitle = "Cuban American National Foundation"

  $scope.blockId = block.menu.block_id
  if (typeof PagesLoadedSrv[$scope.blockId] === "undefined") {
    $rootScope.hideLoader = false
  }

  var service = "query"
  if ($rootScope.isPreview) {
    service = "preview"
  }

  BlockSrv.loaded = BlockSrv[service](
    { id: block.menu.block_id, lang: $rootScope.lang },
    function(response) {
      $scope.components = response.result

      if (typeof PagesLoadedSrv[$scope.blockId] === "undefined") {
        $scope.$emit("pageLoaded")
        PagesLoadedSrv[$scope.blockId] = true
      }

      const pressRealeaseComponent = ComponentsSrv.find($scope.components, 113)
      const childComponents = pressRealeaseComponent.child_component

      let childComponent = childComponents[childComponents.length - 1]

      $scope.pressRealeaseComponent = ComponentsSrv.parseChildComponent(
        childComponent,
        ComponentsSrv.getMapPost()
      )
    }
  )
}

HomeCtrl.$inject = [
  "$rootScope",
  "$scope",
  "block",
  "BlockSrv",
  "PagesLoadedSrv",
  "ComponentsSrv"
]

canfHome.controller("HomeCtrl", HomeCtrl)
