let canfFormJoinus = angular.module("canf.formJoinus", [])

// formJoinus
let formJoinus = function(ThirdPartySrv) {
  return {
    restrict: "E",
    templateUrl: "components/form-joinus.html",
    replace: true,
    scope: {},
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.submit = function(event) {
        event.preventDefault()
        $scope.cfFormJoinus.$setDirty()

        if ($scope.cfFormJoinus.$invalid) {
          $scope.invalid = true
        } else {
          ;($scope.invalid = false), ($scope.sended = true)

          ThirdPartySrv.sendFormJoinUs(
            $scope.formData,
            function(response) {
              console.log("Sended", response)
              $scope.submitted = true
            },
            function(response) {
              console.log(response)
              alert("Error: Try again.")
            }
          )
        }
      }
    }]
  }
}
formJoinus.$inject = ["ThirdPartySrv"]
canfFormJoinus.directive("formJoinus", formJoinus)
