angular
  .module("canf.fragmentsDirectives", [])
  .directive("canfCite", function() {
    return {
      restrict: "E",
      templateUrl: "elements/cite.html",
      replace: true,
      scope: {
        bigText: "@?bigText",
        smText: "@?smText",
        author: "@?author"
      }
    }
  })
