import loaderBlue from "Img/loader-blue.gif"

angular.module("canf.templates", []).run([
  "$templateCache",
  "$rootScope",
  function($templateCache, $rootScope) {
    $rootScope.loaderBlue = loaderBlue

    function requireAll(requireContext) {
      return requireContext.keys().map(function(val) {
        return {
          // tpl will hold the value of your html string because thanks to wepbpack "raw-loader" **important**
          tpl: requireContext(val),

          //name is just the filename
          name: val.replace("./", "")
        }
      })
    }

    // here "../" is my app folder root
    // tplc is the naming convention of the templates
    let modules = requireAll(require.context("Templates", true, /\.html$/))

    modules.map(function(val) {
      $templateCache.put(val.name, val.tpl)
    })
  }
])
