let canfComponentSidebarPostsDirective = angular.module(
  "canf.componentSidebarPostsDirective",
  ["simplePagination"]
)

// componentSidebarPosts
let componentSidebarPosts = function(
  $rootScope,
  $filter,
  ComponentsSrv,
  Pagination
) {
  return {
    restrict: "E",
    templateUrl: "components/sidebar-posts.html",
    replace: true,
    scope: {
      componentSrc: "=",
      map: "=",
      itemsPerPage: "=?limit",
      indexPage: "=?",
      filter: "=?",
      subtitle: "@?",
      url: "@?",
      withoutSocialCounter: "=?"
    },
    controller: ["$scope", "$element", "$attrs", "$transclude", "socialCounter",
    function($scope, $element, $attrs, $transclude, socialCounter) {
      $scope.lang = $rootScope.lang
      $scope.$watch("componentSrc", function() {
        var indexPage
        var baseUrl =
          typeof $attrs.baseUrl !== "undefined" ? $attrs.baseUrl : false
        $scope.component = ComponentsSrv.parseSidebarPosts(
          $scope.componentSrc,
          $scope.map,
          baseUrl,
          $scope.filter
        )
        if ($scope.component) {
          if (typeof $attrs.order !== "undefined") {
            $scope.component.posts = $filter("orderBy")(
              $scope.component.posts,
              $attrs.order,
              true
            )
            angular.forEach($scope.component.posts, function(item, index) {
              if (item.index === $scope.indexPage) {
                indexPage = index
              }
            })
          } else {
            var indexPage = $scope.indexPage
          }

          $scope.perPage =
            typeof $scope.itemsPerPage !== "undefined" ? $scope.itemsPerPage : 5
          $scope.pagination = Pagination.getNew($scope.perPage, 3)
          $scope.pagination.numPages = Math.ceil(
            $scope.component.posts.length / $scope.pagination.perPage
          )

          if (typeof $attrs.ctitle !== "undefined" && $attrs.ctitle) {
            $scope.component.title = $attrs.ctitle
          }

          if (typeof $scope.indexPage !== "undefined") {
            for (var i = 0; i < $scope.pagination.numPages; i++) {
              var start = $scope.pagination.perPage * i
              var end = start + $scope.pagination.perPage
              if (indexPage >= start && indexPage <= end) {
                $scope.pagination.page = i
              }
            }
          }
        }
      })

      $scope.growStartPoint = function() {
        $scope.pagination.start = $scope.pagination.start + 3
      }
    }]
  }
}
componentSidebarPosts.$inject = [
  "$rootScope",
  "$filter",
  "ComponentsSrv",
  "Pagination"
]
canfComponentSidebarPostsDirective.directive(
  "componentSidebarPosts",
  componentSidebarPosts
)
