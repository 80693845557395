let canfFormDonate = angular.module("canf.formDonate", [])

// formDonate
let formDonate = function($rootScope, $sce, $timeout) {
  return {
    restrict: "E",
    templateUrl: "components/form-donate.html",
    replace: true,
    scope: {
      title: "@bbTitle",
      description: "@bbDescription",
      amounts: "=bbAmounts"
    },
    link: function($scope, $element, $attrs) {
      $timeout(function() {
        $scope.translation = $rootScope.translation
      })
    },
    controller: ["$scope", "$element", "$attrs", "$transclude",
    function($scope, $element, $attrs, $transclude) {
      $scope.changeAmountDonate = function(amount, displayOther) {
        $scope.amount = amount
        $scope.displayOther = displayOther
        $scope.amountDonate = $sce.trustAsResourceUrl(
          "https://www.classy.org/checkout/donation?eid=" +
            $attrs.bbEid +
            "&amount=" +
            amount
        )
      }
    }]
  }
}
formDonate.$inject = ["$rootScope", "$sce", "$timeout"]
canfFormDonate.directive("formDonate", formDonate)
