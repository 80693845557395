let canfFilters = angular.module("canf.filters", [])

// findComponent
let findComponent = function(ComponentsSrv, $filter) {
  return function(components, componentID, elementID, filter) {
    if (components) {
      if (typeof filter !== "undefined") {
        return $filter("filter")(
          ComponentsSrv.find(components, componentID, elementID)
            .child_component,
          filter
        )[0]
      } else {
        var data = ComponentsSrv.find(components, componentID, elementID)
        if (
          typeof data !== "undefined" &&
          data !== null &&
          typeof data.value !== "undefined"
        )
          return data.value
      }
    }

    return false
  }
}
findComponent.$inject = ["ComponentsSrv", "$filter"]
canfFilters.filter("findComponent", findComponent)

// findComponentByKey
let findComponentByKey = function($filter) {
  return function(components, compName, keyElement) {
    var ret = ""

    if (Array.isArray(components) && components.length > 0) {
      var filter = $filter("filter")(components, { name: compName }, true)

      if (typeof filter[0] !== "undefined") {
        // angular.forEach(filter[0].)
        var f2 = $filter("filter")(filter[0].data, { name: keyElement }, true)

        if (typeof f2[0] !== "undefined") {
          ret = f2[0].value
        }
      }
    }

    return ret
  }
}
findComponentByKey.$inject = ["$filter"]
canfFilters.filter("findComponentByKey", findComponentByKey)

// getChildComponentsByKey
let getChildComponentsByKey = function($filter) {
  return function(components, compName) {
    var ret = ""

    if (Array.isArray(components) && components.length > 0) {
      var filter = $filter("filter")(components, { name: compName }, true)

      if (typeof filter[0] !== "undefined") {
        ret = filter[0].child_component
      }
    }

    return ret
  }
}
getChildComponentsByKey.$inject = ["$filter"]
canfFilters.filter("getChildComponentsByKey", getChildComponentsByKey)

// findValueByKey
let findValueByKey = function($filter) {
  return function(data, key) {
    var ret = ""

    if (Array.isArray(data) && data.length > 0) {
      var filter = $filter("filter")(data, { name: key }, true)

      if (typeof filter[0] !== "undefined") {
        ret = filter[0].value
      }
    }

    return ret
  }
}
findValueByKey.$inject = ["$filter"]
canfFilters.filter("findValueByKey", findValueByKey)

// findChildComponent
let findChildComponent = function(ComponentsSrv, $filter) {
  return function(components, childComponentID, filter) {
    // console.log("findChildComponent", components, childComponentID);
    if (components) {
      if (typeof filter !== "undefined") {
        var filter = $filter("filter")(
          ComponentsSrv.findChildComponent(components, childComponentID).data,
          filter
        )

        return typeof filter[0] !== "undefined" ? filter[0].value : null
      } else {
        return ComponentsSrv.findChildComponent(components, childComponentID)
      }
    }
  }
}
findChildComponent.$inject = ["ComponentsSrv", "$filter"]
canfFilters.filter("findChildComponent", findChildComponent)

// findCoupleByKey
let findCoupleByKey = function($filter) {
  return function(components, compName, keyKey, keyValue, keyToFind) {
    // console.log("findCoupleByKey", compName, keyKey, keyValue, keyToFind);
    var ret = ""

    if (Array.isArray(components) && components.length > 0) {
      var filter = $filter("filter")(components, { name: compName }, true)

      if (typeof filter[0] !== "undefined") {
        // angular.forEach(filter[0].)
        var f2 = $filter("filter")(
          filter[0].child_component,
          { data: { name: keyKey, value: keyToFind } },
          true
        )

        if (typeof f2[0] !== "undefined") {
          var f3 = $filter("filter")(f2[0].data, { name: keyValue }, true)

          if (typeof f3[0] !== "undefined") {
            ret = f3[0].value
          }
        }
      }
    }

    return ret
  }
}
findCoupleByKey.$inject = ["$filter"]
canfFilters.filter("findCoupleByKey", findCoupleByKey)

// parseDataChild
let parseDataChild = function($filter) {
  return function(child, filter) {
    if (child) {
      return $filter("filter")(child.data, filter)[0].value
    }
  }
}
parseDataChild.$inject = ["$filter"]
canfFilters.filter("parseDataChild", parseDataChild)

// srcLocalToRemote
let srcLocalToRemote = function(SITE_URL) {
  return function(url) {
    if (url) {
      return url.replace("http://localhost", SITE_URL)
    }
  }
}
srcLocalToRemote.$inject = ["SITE_URL"]
canfFilters.filter("srcLocalToRemote", srcLocalToRemote)

// findIndexFromObject
let findIndexFromObject = function() {
  return function(obj, index) {
    if (obj) {
      return obj[Object.keys(obj)[0]]
    }
  }
}
canfFilters.filter("findIndexFromObject", findIndexFromObject)

// trustAsResourceUrl
let trustAsResourceUrl = function($sce) {
  return function(input) {
    if (input) {
      return $sce.trustAsResourceUrl(input)
    }
  }
}
trustAsResourceUrl.$inject = ["$sce"]
canfFilters.filter("trustAsResourceUrl", trustAsResourceUrl)

// parsedURL
let parsedURL = function() {
  return function(url) {
    if (url) {
      var parsedURL = new URL(url)
      return parsedURL.pathname
    }
  }
}
canfFilters.filter("parsedURL", parsedURL)

// toTrusted
let toTrusted = function($sce) {
  return function(value) {
    return $sce.trustAsHtml(value)
  }
}
toTrusted.$inject = ["$sce"]
canfFilters.filter("toTrusted", toTrusted)

// htmlToPlaintext
let htmlToPlaintext = function() {
  return function(text) {
    return angular.element(text).text()
  }
}
canfFilters.filter("htmlToPlaintext", htmlToPlaintext)
